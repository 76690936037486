import styled, { css } from 'styled-components';
import { Col } from 'react-bootstrap';

const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
import {Modal} from "react-bootstrap";
const MainContentContainer = styled.div`
  margin: 20px 20px 20px 14px;;
  width: calc(100% - 34px);
  float: left;
  font-family: ${RegularFont};
`;

const ProfileSummary = styled.div`
  background-color: white;
  width: 100%;
  float: left;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
`;

const TitleContent = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #e2e2e4;
  text-transform: uppercase;
  width: 100%;
  float: left;
  padding: 11px 20px;
  text-align: ${(props) => props.center ? 'center' : 'left'};
  
  > svg {
    margin-top: 4px;
  }
  
  > img {
    width: 20px;
    height: 20px;
    float: left;
  }
  
  > span {
    margin-left: ${(props) => props.wellBeing ? '10px' : '0'};
    font-family: ${BoldFont};
    line-height: 20px;
    > span {
      color: #159fc9;
    }
  }
`;

const ContentContainer = styled.div`
  padding: ${({activity}) => activity ? '20px' : '20px 20px 15px 20px'};
  float: left;
  width: 100%;
  text-align: ${(props) => props.activity ? 'left' : 'center'};

  @media (max-width: 500px) {
    padding: ${({activity}) => activity ? '20px' : '20px 10px 15px 10px'};
  }
  
  ${(props) => props.health && css`
    min-height: 370px;
  `}
`;

const CheckLogo = styled.div`
  height: ${({height}) => height ? height : '33px'};
  width: ${({width}) => width ? width : '33px'};
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: auto;
  background-color: ${({ completed }) => completed ? '#7cbf41' : '#ef6727'};
  display: table;
  position: relative;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid white;
  box-shadow: 0 0 0 3px ${({ completed }) => completed ? '#7cbf41' : '#ef6727'};
  font-size: 14px;
  margin-bottom: 13px;
  cursor: ${({ cursor }) => cursor ? 'default' : 'pointer'};
   input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({imageReload}) => imageReload && '0'};
  }
  > i {
    margin-top: 9px;
  }
  .fa-times {
    margin: 0px !important;
  }

  > span {
    margin-top: 4px;
    float: left;
    width: 100%;
    font-size: 16px;
    font-family: ${BoldFont};
  }
  
  ${(props) => props.header && css`
    box-shadow: none;
    display: inline-block;
    height: 23px;
    width: 23px;
    margin-bottom: 0;
    > i {
      margin-top: 4px;
    }  
  `}
`;

const ProfileInformation = styled.div`
  font-size: 14px;
  > span {
    color: rgb(153,153,153);
    font-family: ${RegularFont};
    font-size: 12px;
  }
  
  ${({ boldFont }) => boldFont && css`
    font-family: ${BoldFont};
    color: rgb(153,153,153);
  `}
`;

const CompleteInformation = styled.p`
  font-size: 14px;
  color: rgb(153,153,153);
  text-align: ${({ activity }) => activity ? 'right' : 'center'};
  width: ${({ activity }) => activity ? '100%' : 'calc(100% - 20px)'};
  margin: ${({ activity }) => activity ? '5px 0 0 0' : '10px'};
  float: left;
  margin: ${({ marginBottom }) => marginBottom && '0 0 20px'};
  
  > span {
    font-family: ${BoldFont};
  }
`;

const StyledCol = styled(Col)`
  width: calc(100%/5);
  cursor: pointer;
  @media (max-width: 767px) and (min-width: 530px) {
    display: table-cell; 
  }
  @media (max-width: 530px) {
    width: auto;
    margin-bottom: 20px;
  }
`;

const RequiredTime = styled.p`
  color: rgb(153,153,153);
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 25px;
  
  > span {
    font-size: 30px;
    color: #159fc9;
    font-family: ${BoldFont};
  }
`;

const ButtonContainer = styled.div`
  margin: 25px 0;
  float: left;
  width: 100%;
  position: relative;
  > ul {
    list-style-type: none;
    position: absolute;
    left: 47%;
    top: 13px;
    margin-bottom: 0;
    padding-left: 0;
    
    > li {
      float: left;
      padding-left: 12px;
    }
  }
  
  @media (max-width: 530px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    > ul {
      position: unset;
      float: left;
    }
  }
`;

const ConnectButton = styled.button`
  background-color: ${(props) => props.color || '#ef6827'};
  border: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid #ef6827'};
  color: white;
  white-space: nowrap;
  padding: 10px 18px;
  font-size: ${({ biggerFont }) => biggerFont ? '13px' : '12px'};
  line-height: 1.428571429;
  float: ${(props) => props.next ? 'right' : 'left'};
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};
  height: ${({ biggerFont }) => biggerFont ? '42px' : '39px'};
  width: ${({ fixWidth }) => fixWidth ? '145px' : 'auto'};
  
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  @media (max-width: 530px) {
    float: left;
    margin: 15px 0;
    width: 100%;
  }
`;

const NavigateButton = styled.div`
  float: left;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #cecfd1;
  cursor: pointer;
  
  ${({ active }) => active && css`
    width: 15px;
    height: 15px;
    background-color: #ef6827;
  `}
`;

const AmigoInfo = styled.div`
  font-size: 14px;
  color: rgb(153,153,153);
  padding: 22px 20px 24px 20px;
  float: left;
  width: 100%;
  
  > div {
    float: left;
    width: 40%;
    margin-left: 60%;

    @media (max-width: 500px) {
      line-height: 20px;
    }
  }
`;

const Activities = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 50px;
`;

const Container = styled.div`
  display: inline-block;
  width: ${({ behaviorChallenge }) => behaviorChallenge ? '100%' : 'calc((100%/3) - 14px)'};
  background-color: ${({ behaviorChallenge }) => behaviorChallenge ? 'transparent' : 'white'};
  float: left;
  box-shadow: ${({ behaviorChallenge }) => behaviorChallenge ? 'unset' : '1px 1.732px 46px rgba(0,0,0,0.18)'};
  margin-right: 20px;
  margin-top: ${({ behaviorChallenge }) => !behaviorChallenge && '20px'};
  height: ${({ fixedHeight }) => fixedHeight ? '450px' : 'auto'};

  &:last-child {
    margin-right: 0;
  }
  
  @media(max-width: 1200px) {
    width: 100%;
    height: auto;
  }
`;

const ActivityContainer = styled.div`
  height: 220px;
  width: 100%;
  float: left;

  @media (max-width: 530px) {
    height: auto;
  }

  > div {
    > svg {
      width: 25px;
      margin-right: 5px;
      height: 25px;
      margin-top: 0;
    }
  }
`;

const SvgIcon = styled.img`
  max-width: 25px;
  width: 8%;
  min-width: 10px;
`;

const ActivityBar = styled.div`
  height: ${({ isBigBar }) => isBigBar ? '15px' : '8px'};
  width: ${({ wellBeing }) => wellBeing ? '100%' : 'calc(100% - 30px)'};
  background-color: #ededed;
  border-radius: 30px;
  display: inline-block;
`;

const InnerActivityBar = styled.div`
  height: ${({ isBigBar }) => isBigBar ? '15px' : '8px'};
  width: ${({ width }) => width};
  background-color: ${({ color }) => color};
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  
  ${({width}) => width === '100%' && css`
    border-radius: 30px;
  `}
`;

const InputButtons = styled(ConnectButton)`
  background-color: ${({ goals }) => goals ? '#159fc9' : '#7bbf41'};
  border: ${({ goals }) => goals ? '1px solid #159fc9' : '1px solid #7bbf41'};
  float: ${({ goals }) => goals ? 'none' : 'right'};
  width: 150px;
  
  @media (max-width: 1390px) and (min-width: 1201px) {
    width: auto;
  }
  
  @media (max-width: 1200px) and (min-width: 531px) {
    width: 150px;
  }
  
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const WorkoutContent = styled.div`
  font-size: 16px;
  text-align: center;
  float: left;
  width: 100%;
  padding-bottom: ${({ buttons }) => buttons ? '' : '15px'};
  padding-top: ${({ buttons }) => buttons ? '' : '5px'};
  height: ${({ buttons}) => buttons ? '50px' : '75px'};
  cursor: auto;
  height: auto;
  text-align: ${({ center }) => center ? 'center' : 'justify'};

  .category {
    color: rgb(153,153,153);
    padding: 0px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 5px;
    white-space: initial;
  }


  
  @media (max-width: 530px) {
    height: auto;
  }
`;

const ActivityOverview = styled.div`
  width: calc((100%/3) - 14px);
  float: left;
  background-color: white;
  margin-top: 20px;
  padding: 10px 0;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  height: 450px;
  
  @media(max-width: 1200px) {
    width: 100%;
  }
`;

const TileButton = styled(ConnectButton)`
  background-color: ${({ active }) => active ? '#159fc9' : '#ededed'};
  border-color: ${({ active }) => active ? '#159fc9' : '#ededed'};
  margin-left: 9px;
  margin-top: 21px;
  margin-bottom: 2px;
  padding: 5px 18px;
  font-weight: normal;
  color: ${({ active }) => active ? 'white' : 'rgb(153,153,153)'};
  height: 30px;

  &:first-child {
    margin-left: 0;
  }

  &:focus, &:active, &:hover {
    outline: none;
    border: ${({ active }) => active ? '1px solid #159fc9' : '1px solid #ededed'};
  }

  @media (max-width: 530px) {
    width: auto;
    margin-left: 9px;
  }
`;

const CurrentTime = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  .week {
    font-size: 20px;
  }
  > span {
    vertical-align: super;
    font-family: ${BoldFont};
    padding: 0 21px;
  }
`;

const HealthOverview = styled.div`
  background-color: white;
  margin-left: 20px;
  width: calc(66.66% - 7px);
  float: left;
  margin-top: 20px;
  padding: 10px 0;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  
  @media(max-width: 1200px) {
    width: 100%;
    margin-left: 0;
  }
`;

const AverageWrapper = styled.div`
  float: left;
  background-color: #ededed;
  margin: 10px;
  width: calc((100% - 80px)/4);
  height: 173px;
  text-align: center;
  
  > div {
    color: rgb(153,153,153);
  }

  @media (max-width: 767px) and (min-width: 501px) {
    min-width: 147px;
  }

  @media (max-width: 500px) {
    width: calc(50% - 20px);
  }
`;

const ColoredArea = styled.div`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  text-align: center;
  margin: auto;
  > div {
    color: white;
    font-size: 20px;
    float: left;
  }
`;

const GoalContainer = styled.div`
  width: 100%;
  float: left;
  border-bottom: 1px solid #000000;
  padding: 25px 5px;

  @media (max-width: 500px) {
    padding: 25px 15px;
  }
  
  &:last-child {
    border-bottom: none;
    padding-bottom: 10px;
  }
`;

const GoalsContainer = styled.div`
  margin-top: 21px;
  float: left;
  width: 100%;
  > div {
    > span {
      color: rgb(153,153,153);
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 36px;
    }
  }
`;

const GoalContent = styled.div`
  display: inline-block;
  width: 50%;
  text-align: center;
  float: left;
`;

const GoalTitle = styled.div`
  color: #23253A;
  font-size: 13px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: ${MediumFont}
  text-align: center;
`;

const ActualGoal = styled.div`
  width: 100%;
  font-size: 16px;
  letter-spacing: 0px;
  color: #949393;
  text-align: center;
  font-family: ${MediumFont};
  font-weight: 600;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  @media (max-width: 1295px) and (min-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 330px) {
    font-size: 16px;
  }
`;

const GoalNumbering = styled.div`
    height: 29px;
    width: 29px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #FF6D4A;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: row;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid white;
    box-shadow: 0 0 0 3px #FF6D4A;
    font-size: 14px;
    cursor: default;
    float: left;
    margin-right: 20px;
`;

const StyledPencil = styled.i`
  float: right;
  color: #fff;
  margin-right: ${({removeRight}) => !removeRight && '14px'};
  cursor: pointer;
  padding: 2px;
`;

const WellBeingResult = styled.div`
  margin-top: 14px;
  float: left;
  width: 100%;
`;

const WellBeingTitle = styled.span`
  float: left;
  font-family: ${RegularFont} !important;
`;

const GraphContainer = styled.div`
  width: 100%;
  float: left;
  margin-top: 25px;
`;

const Graph = styled.div`
  width: calc(100% / 4);
  display: inline-block;
  float: left;
  font-size: 12px;
  
  > span {
    word-wrap: break-word;
  }
`;

const ImageContainer = styled.div`
  padding: 15px 0 7px 0 !important;
  height: 45px;
  width: 25px;
  margin: auto;
  
  > svg {
    width: 25px;
  }
`;

const OverviewContainer = styled.div`
  margin: 40px 0 0 0;
  float: left;
  width: 100%;
  > svg {
    width: 25px;
    margin-right: 5px;
    height: 25px;
  }
`;

const WellBeingContainer = styled.div`
  padding: 0;
  float: left;
  width: 100%;
  text-align: left;
  padding: 0 10px;
`;

const AssessmentData = styled.div`
  padding: 14px 40px 25px;
  float: left;
  width: 100%;
`;

const GraphContentContainer = styled.div`
  display: inline-block;
  width: 100%;
  background-color: white;
  float: left;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  margin-top: 20px;
  height: 450px;
`;

const HealthValue = styled.span`
  font-size: ${(props) => props.size || '3vmin'};
  word-break: break-all;
`;

const ButtonsContainer = styled.div`
  float: left;
  width: 100%;
  padding-left: calc((100% - 250px)/2);
`;

const HealthInformation = styled.div`
  text-align: center;
  vertical-align: middle;
  line-height: 85px;
  width: inherit;
  height: inherit;
`;

const HealthTitle = styled.div`
  float: left;
  width: 100%;
  padding: 10px 0;
  font-size: 12px;
`;

const MainGraph = styled.div`
  position: relative;
  margin-top: -7px;
`;

const GraphValue = styled.div`
  position: absolute;
  top: 46%;
  width: 100%;
  > div {
    font-size: 30px;
    font-family: ${BoldFont};
    letter-spacing: -1;
    margin-bottom: -7px;
  }
  > span {
    font-size: 12px;
    color: rgb(153,153,153);
  }
`;

const SpittedGraphValue = styled.div`
  position: absolute;
  width: 100%;
  top: 38%;
  font-size: 12px;
  color: ${({color}) => color && color || 'black'};
  font-family: ${BoldFont};
`;

const CompletionContainer = styled.div`
  float: left;
  width: 100%;
  padding: 10px 0;
`;

const LoaderContainer = styled.div`
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

const WelcomeMessage = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #999999;
  margin: ${({ margin }) => margin && margin};
  font-family: Proxima-Nova-Regular;
`;

const WelcomeButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${({ small }) => small ? '0px' : '50px'};
  margin-bottom: ${({ small }) => small ? '0px' : '40px'};
  padding: ${({ small }) => small && '10px'}
  .page {
    font-size: ${({ small }) => small ? '15px' : '20px'};
    margin: ${({ small }) => small ? '10px' : '15px'};
    .iconBlue {
      color: #159fc9;
      cursor: pointer;
    }
    .iconDisable {
      color: lightgrey;
      cursor: not-allowed;
    }
  }
`;
const ImageInput = styled.label`
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
`;

const StyledModal = styled(Modal)`
.modal-content{
  box-shadow: none;
  border: none;
}
  .modal-dialog {
    width: 550px;
    border-radius: 6px;
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    box-shadow: none;
    .close {
      margin-top: -30px;
    }
    @media (max-height: 530px) {
      transform: none !important;
      box-shadow: none;
    }
    @media (max-width: 767px) {
      width: 90%;
      box-shadow: none;
    }
  }
`;

const ImageContainerPopup = styled.div`
Width: 100%;
height: 240px;
display: flex;
background: linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
display: flex;
justify-content: center;
border-radius: 0 0 6px 6px;
>div{
  width: 375px;
  height: 231px;
  margin-top: 20px;
 >img{
   width: 100%;
   display: flex;
   margin: auto;
 }
}
`;

const PopupHeading = styled.div`
Width: 100%;

font-family: 'Rubik-Medium';
font-size: 32px;
line-height: 32px;
color: #FD7175;
display: flex;
justify-content: center;

margin-top: 25px;
`;

const PopupBody = styled.div`
margin: 15px 15px 0px 15px;
padding-bottom: 15px;
border-bottom: 1px solid rgba(156, 156, 156, 0.4);

font-family: Rubik;
font-size: 16px;
line-height: 21px;
color: #0D4270;
text-align: center;
@media (max-width: 530px) {
 .mobileViewHidden{
  display:none;
 }

}
.mobileViewText{
  display:none;
}
  @media (max-width: 530px){
    .mobileViewText{
      display: inline-block;
    font-family: Rubik;
    font-size: 16px;
    line-height: 21px;
    color: #0D4270;
    text-align: center;
    }
  }
`;

const StartButtonContainer = styled.div`
width: 100%;
padding: 15px 0px;
display: flex;
justify-content: center;
>button{
  width: 55%;
  height: 50px;
  background: #69C2FF;

  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 20px;
  color:#FFFFFF;
  outline: none;
  border: none;
  border-radius: 6px;
}

@media (max-width: 530px){display:none;}
`;
const AppButtonsContainer = styled.div`
display:none;

@media (max-width: 530px){
  width: 100%;
padding: 15px 0px;
display: flex;
justify-content: center;
flex-direction:column;
padding-bottom: 30px;
>div{text-align:center;
  display: flex;
justify-content: center;
align-items:center;
width:100%;
  color:#69c2ff;
  font-size:18px;
  font-family:rubik-medium;
  line-height:24px;

}
div{width:100%;
  display: flex;
justify-content: center;
align-items:center;}
a{
  width:90%;
  text-decoration:none;
  margin:15px 0 0px 0px;
  img{
 width:100%;
    height:60px;
  }
}
}

`;

export { MainContentContainer, ProfileSummary, TitleContent, ContentContainer, CheckLogo, ProfileInformation, CompleteInformation,
  StyledCol, RequiredTime, ButtonContainer, ConnectButton, NavigateButton, AmigoInfo, Activities, Container, ActivityBar, InnerActivityBar, SvgIcon,
  InputButtons, WorkoutContent, ActivityContainer, ActivityOverview, TileButton, CurrentTime, HealthOverview, AverageWrapper, ColoredArea, GoalContent,
  GoalsContainer, GoalTitle, ActualGoal, GoalContainer, GoalNumbering, StyledPencil, WellBeingResult, WellBeingTitle, GraphContainer, Graph,
  ImageContainer, OverviewContainer, WellBeingContainer, AssessmentData, GraphContentContainer, HealthValue, ButtonsContainer, HealthInformation,
  HealthTitle, MainGraph, GraphValue,AppButtonsContainer, SpittedGraphValue, CompletionContainer, LoaderContainer, WelcomeMessage, WelcomeButtonContainer,ImageInput, StyledModal, ImageContainerPopup, PopupHeading, PopupBody, StartButtonContainer};
