import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  const buttonStyle = {
    background: "#ef6827",
    color: "white",
    borderRadius: "30px",
    height: "35px",
    width: '158px',
    border: "1px solid #ef6827",
    fontSize: "13px"
  };
  const bannerStyle = {
    background: "#2B373B"
  };
  return <CookieConsent
    location="bottom"
    buttonText="I ACCEPT"
    cookieName="TAMakesYouHappy"
    style={ bannerStyle }
    buttonStyle={ buttonStyle }
    expires={ 150 }
  >This website uses cookies to enhance the user experience.</CookieConsent>
}

export default CookieBanner;